import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import SurveyLanguageDropdown from '../dropdowns/SurveyLanguageDropdown';
import CountdownTimer from './CountdownTimer';
import { getAllQuestionAndAnswer, getDataByLanguage, startSurvey, submitSurvey } from "./service";
import SingleAnswerType from '../answerTypes/singleAnswerType';
import MultipleAnswerType from '../answerTypes/multipleAnswerType';
import NumberScaleAnswerType from '../answerTypes/numberscaleAnswerType';
import SubjectiveAnserType from '../answerTypes/subjectiveAnserType';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import NumberScaleComponent from '../answerTypes/numberscaleAnswerType';
import SurveyExpired from './surveyExpired';

export default class ParticipantAttendees extends Component {
    constructor(props) {
        super(props);
        this.answerTypeRef = React.createRef();
        this.state = {
            participantAttendees: this.props.participantAttendees || {
                endDate: '',
                endTime: '',
                participantName: '',
                startDate: '',
                startTime: '',
                status: '',
                surveyEndReminder: '',
                surveyName: '',
                surveyStartReminder: '',
                surveyId: 0,
                startDateTime: '',
                endDateTime: '',
                description: '',
                participantId: 0,
                submissionStartDateTime: null,
                submittedOn: null,
                isSubmitted: '',
                languageId: '',
                isRandomized: ''
            },
            languageId: '1',
            editable: false,
            shouldRender: false,
            data: [],
            attendeesEntity: {},
            convertedDescription: '',
            surveyStarted: false,
            order: [],
            loading: false,
        }

    }

    componentDidMount() {
        const currentDate = new Date();
        const futureDate = new Date(this.state.participantAttendees.startDateTime);
        this.convertHTMLToText(this.state.participantAttendees.description);
        if (currentDate >= futureDate) {
            this.setState({ shouldRender: true, languageId: 1 });
            if (this.state.participantAttendees.submissionStartDateTime !== null) {
                let currentLanguage = this.state.participantAttendees.languageId;
                this.setState({
                    languageId: currentLanguage,
                    editable: true
                }, () => {
                    this.fetchList(this.state.languageId);
                    this.handleLanguageChange(this.state.languageId);
                })

            }
        } else {
            this.interval = setInterval(() => {
                const updatedCurrentDate = new Date();
                if (updatedCurrentDate >= futureDate) {
                    this.setState({ shouldRender: true });
                    clearInterval(this.interval);
                }
            }, 60000);
        }
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    handleLanguageChange = (e) => {
        let languageId;
        if (typeof e === 'object') {
            if (e.target.value === 'Select Language') {
                languageId = 1;
            } else {
                languageId = e.target.value;
            }
        } else {
            languageId = e;
        }
        const { surveyId } = this.state.participantAttendees;

        getDataByLanguage(languageId, surveyId)
            .then(res => {
                const updatedParticipantAttendees = { ...this.state.participantAttendees };

                if (res.data) {
                    updatedParticipantAttendees.surveyName = res.data.name;
                    updatedParticipantAttendees.description = res.data.description;
                    updatedParticipantAttendees.submissionStartDateTime = this.state.participantAttendees.submissionStartDateTime;
                }
                this.setState({
                    languageId,
                    participantAttendees: updatedParticipantAttendees
                }, () => {
                    if (languageId === '1' || languageId === 'Select Language') {
                        this.fetchList(1);
                    } else {
                        this.fetchList(languageId);
                    }
                    this.convertHTMLToText(updatedParticipantAttendees.description);
                });
            })
            .catch(error => {
                console.log(error);
            });
    }



    fetchList = (languageId) => {
        try {
            getAllQuestionAndAnswer(
                this.props.participantAttendees.surveyId,
                languageId,
                this.state.participantAttendees.participantId
            )
                .then((res) => {
                    if (res.status === 'OK') {
                        var dbData = null;
                        const { order } = this.state;

                        var dbOrder = order;

                        if (this.state.participantAttendees.isRandomized === true) {
                            if (order.length > 0) {
                                dbData.sort((a, b) => {
                                    const indexA = order.findIndex(item => item.id === a.id);
                                    const indexB = order.findIndex(item => item.id === b.id);
                                    return indexA - indexB;
                                });
                            } else {
                                dbData = this.shuffleArray(res.data.surveyQuestionAnswer);

                                dbData.forEach((element, index) => {
                                    dbOrder.push({
                                        id: element.surveyQuestionResponse.id
                                    })
                                });

                            }
                            res.data.surveyQuestionAnswer = dbData;


                        } else {
                            dbData = res.data.surveyQuestionAnswer.sort((a, b) => a.surveyQuestionResponse.sortOrder - b.surveyQuestionResponse.sortOrder);
                            res.data.surveyQuestionAnswer = dbData;
                        }



                        this.setState({
                            data: res.data,
                            order: dbOrder,
                            loading: true
                        });
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        } catch (error) {
            console.error(error);
        }
    };

    shuffleArray(array) {
        const shuffledArray = [...array];
        for (let i = shuffledArray.length - 1; i > 0; i--) {
            const randomIndex = Math.floor(Math.random() * (i + 1));
            [shuffledArray[i], shuffledArray[randomIndex]] = [shuffledArray[randomIndex], shuffledArray[i]];
        }
        return shuffledArray;
    }

    convertHTMLToText(html) {
        const parser = new DOMParser();
        const doc = parser.parseFromString(html, 'text/html');
        const text = this.parseNode(doc.documentElement);
        this.setState({ convertedDescription: text });
    }

    parseNode(node) {
        let result = '';

        for (let i = 0; i < node.childNodes.length; i++) {
            const child = node.childNodes[i];

            if (child.nodeType === Node.TEXT_NODE) {
                result += child.textContent;
            } else if (child.nodeType === Node.ELEMENT_NODE) {
                if (child.nodeName === 'STRONG' || child.nodeName === 'B') {
                    result += '<strong>' + this.parseNode(child) + '</strong>';
                } else if (child.nodeName === 'EM' || child.nodeName === 'I') {
                    result += '<em>' + this.parseNode(child) + '</em>';
                } else if (child.nodeName === 'SPAN' && child.style.color) {
                    const color = child.style.color;
                    result += '<span style="color:' + color + '">' + this.parseNode(child) + '</span>';
                } else {
                    result += this.parseNode(child);
                }
            }
        }

        return result;
    }





    onSurveyStart = () => {
        const { participantAttendees, languageId } = this.state;

        startSurvey(participantAttendees.surveyId, languageId, participantAttendees.participantId)
            .then(res => {
                if (res.status === 'OK') {
                    this.setState({
                        attendeesEntity: res.data,
                        surveyStarted: true,
                    }, () => {
                        if (languageId === '1' || languageId === 'Select Language') {
                            this.fetchList(1);
                            this.handleLanguageChange(1);
                        } else {
                            this.fetchList(languageId);
                            this.handleLanguageChange(languageId)
                        }
                        window.location.reload();
                    });
                } else {
                    console.error("Invalid response received from startSurvey");
                }
            })
            .catch(error => {
                console.error(error);
            });
    };
    submit = () => {
        if (this.answerTypeRef.current) {
            this.answerTypeRef.current.triggerBlur();
        }
        this.onSubmitSurvey();
    }
    onSubmitSurvey = () => {
        const { participantAttendees, languageId, data } = this.state;
          
        for (let i = 0; i < data.surveyQuestionAnswer.length; i++) {
            if (data.surveyQuestionAnswer[i].surveyQuestionResponse.isMandatory === true) {
                const answer = data.surveyQuestionAnswer[i].surveyResponseAnswers.answers;
                // if (answer === null || answer.trim() === '' || answer === "[]") {
                if (answer === null || answer.trim() === '' || (Array.isArray(answer) && answer.length === 0) || answer === "[]") {
                    toast.error("Please Answer All Mandatory Questions");
                    return;
                }
            } else {
                continue;
            }
        }

        submitSurvey(participantAttendees.surveyId, languageId, participantAttendees.participantId)
            .then(res => {
                if (res.status === 'OK') {
                    let message = res.data;
                    this.props.history.push('/survey/submitted', { message: message });
                } else {
                    console.error("Invalid response received from startSurvey");
                }
            })
            .catch(error => {
                console.error(error);
            });
    }
    onSaveAsDraft = () => {
        toast.success("Survey Saved As Draft");
    };

    formatDate = (dateString) => {
        const date = new Date(dateString);
        const options = { day: '2-digit', month: 'short', year: 'numeric' };
        const formattedDate = date.toLocaleDateString('en-US', options);

        // Removing the comma from the formatted date
        const formattedDateWithoutComma = formattedDate.replace(',', '');

        // Adding a space before the month
        const [month, day, year] = formattedDateWithoutComma.split(' ');
        return `${day}  ${month} ${year}`;
    };

    formatTime = (dateTime) => {
        const date = new Date(dateTime);
        const hours = date.getHours();
        const minutes = date.getMinutes().toString().padStart(2, '0'); 
        let period = 'AM';

        let parsedHours = hours;

        if (hours >= 12) {
            period = 'PM';
            if (hours > 12) {
                parsedHours = hours - 12;
            }
        }

        if (parsedHours === 0) {
            parsedHours = 12;
        }

        return `${parsedHours}:${minutes} ${period}`;
    };




    render() {
        const { participantAttendees, languageId, editable, shouldRender, data, convertedDescription, surveyStarted } = this.state;
        // const targetDate = new Date('2023-06-10T13:54:00');
        // console.log(this.formatTime(participantAttendees));
        console.log(surveyStarted);
        const targetStartDate = new Date(participantAttendees.startDateTime);
        const targetEndDate = new Date(participantAttendees.endDateTime);
        const now = new Date().getTime();
        const surveyExpired = targetEndDate.getTime() - now >= 0;
        console.log(surveyExpired)
        return (
            <div className="">
                <Helmet>
                    <title> Survey Page </title>
                </Helmet>
                {surveyExpired && 
                <div className="content">
                    <div className="">
                        <div className="page-header survey-header-sticky mt-0">
                            <div className="row align-items-center">
                                <div className="col">
                                    <h3 className="page-title">{participantAttendees.surveyName} </h3>
                                </div>
                                {shouldRender &&
                                    <div className="float-right col-auto ml-auto">
                                        <CountdownTimer targetDate={targetEndDate} />
                                    </div>}
                            </div>
                        </div>
                        {/* <div className="row user-tabs">
                            <div className="card tab-box tab-position">
                                <div className="col-lg-12 col-md-12 col-sm-12 line-tabs">    
                                    <ul className="nav nav-tabs">
                                    onClick={() => { this.publishSurvey() }}
                                    </ul>
                                    <button className="btn btn-primary add-btn ml-auto mb-3 "><i class="fa fa-upload"></i> Start Survey</button>
                                </div>
                            </div>

                        </div> */}

                        <div className="survey-body">
                            <div className='row survey-backround'>
                                <div className='col-md-12' >
                                    <div className='survey-header-element' style={{ padding: '16px' }}>
                                        {shouldRender &&
                                            <div className='row mt-1 mb-1'>

                                                <div className='desc-box' dangerouslySetInnerHTML={{ __html: convertedDescription }}></div>
                                            </div>}
                                    </div>
                                    {/* <fieldset className='border border-dark p-4'> */}
                                    {/* <legend className='float-none w-auto p-2'>Product Survey{participantAttendees.surveyName}</legend> */}

                                    {shouldRender ? <>

                                        <div className='row' style={{ padding: '16px' }}>
                                            <div className='col-md-6 survey-part-info-left'>
                                                <h4>Start:</h4>
                                                <p>
                                                    {participantAttendees.endDate && this.formatDate(participantAttendees.startDate)} {participantAttendees.endTime && this.formatTime(participantAttendees.startTime)}
                                                </p>
                                            </div>
                                            <div className='col-md-6 '>
                                                <div className='col survey-part-info-right'>

                                                    <h4>End:</h4>    <p>
                                                        {participantAttendees.endDate && this.formatDate(participantAttendees.endDate)}  {participantAttendees.endTime && this.formatTime(participantAttendees.endTime)} </p>
                                                </div>
                                            </div>

                                        </div>
                                        <div className='col-md-12 d-flex justify-content-center'>
                                            <div className='survey-part-info-center'>
                                                {participantAttendees.submissionStartDateTime ? <p>Selected Language</p> : <p>Select Language</p>}
                                            </div>
                                        </div>

                                        <div className='row'>

                                            <div className='col-md-12 d-flex justify-content-center'>

                                                <SurveyLanguageDropdown
                                                    surveyId={participantAttendees.surveyId}
                                                    readOnly={editable}
                                                    defaultValue={languageId}
                                                    onChange={this.handleLanguageChange}
                                                />
                                            </div>
                                        </div>

                                        <div className="d-flex justify-content-center">
                                            {participantAttendees.submissionStartDateTime === null && !surveyStarted && shouldRender && (
                                                <button className="btn btn-primary mb-3 mx-0 d" onClick={this.onSurveyStart}>
                                                    Start Survey
                                                </button>
                                            )}


                                        </div>

                                        {data && participantAttendees.submissionStartDateTime !== null && data.surveyQuestionAnswer && data.surveyQuestionAnswer.length > 0 ? (
                                            <div className="">
                                                {data.surveyQuestionAnswer.map((item, index) => (
                                                    <div className='each-question' style={{ marginTop: "0px", marginBottom: "0px", borderRadius: '0px' }} key={`${index}-${item.surveyQuestionResponse.id}`}>
                                                        <br />
                                                        <label className='h1'>
                                                            {index + 1}. {item.surveyQuestionResponse.isMandatory ? (
                                                                <span>{item.surveyQuestionResponse.question}<span style={{ color: 'red' }}>*</span></span>
                                                            ) : (
                                                                <span>{item.surveyQuestionResponse.question}</span>
                                                            )}
                                                        </label>

                                                        <div className="form-froup">
                                                            {/* <label className='survey-label'>Category Name</label> */}
                                                            <input className='survey-input-muted' type="hidden" value={item.surveyQuestionResponsecategoryName} readOnly />
                                                        </div>

                                                        {item.surveyQuestionResponse.answerType === 'SINGLE_ANSWER' ? (
                                                            <SingleAnswerType answers={item.surveyQuestionResponse.answers} participantAttendees={this.state.participantAttendees} surveyQuestionResponse={item.surveyQuestionResponse} surveyResponseAnswers={item.surveyResponseAnswers} fetchList={this.fetchList} />
                                                        ) : item.surveyQuestionResponse.answerType === 'MULTIPLE_ANSWER' ? (
                                                            <MultipleAnswerType answers={item.surveyQuestionResponse.answers} participantAttendees={this.state.participantAttendees} surveyQuestionResponse={item.surveyQuestionResponse} surveyResponseAnswers={item.surveyResponseAnswers} fetchList={this.fetchList} />
                                                        ) : item.surveyQuestionResponse.answerType === 'NUMBER_SCALE' ? (
                                                            <NumberScaleComponent answers={item.surveyQuestionResponse.answers} participantAttendees={this.state.participantAttendees} surveyQuestionResponse={item.surveyQuestionResponse} surveyResponseAnswers={item.surveyResponseAnswers} fetchList={this.fetchList} />
                                                        ) : item.surveyQuestionResponse.answerType === 'SUBJECTIVE' ? (
                                                            <SubjectiveAnserType ref={this.answerTypeRef} answers={item.surveyQuestionResponse.answers} participantAttendees={this.state.participantAttendees} surveyQuestionResponse={item.surveyQuestionResponse} surveyResponseAnswers={item.surveyResponseAnswers} fetchList={this.fetchList} />
                                                        ) : (
                                                            item.surveyQuestionResponse.answers.map((answer) => (
                                                                <div key={answer.id}>{answer.answer}</div>
                                                            ))
                                                        )}
                                                    </div>
                                                ))}
                                                <div className='d-flex justify-content-end mt-4'>
                                                    <button className="btn btn-sm btn-success mb-3 ml-2 mx-0" onClick={this.onSaveAsDraft} style={{ fontSize: '16px' }}>
                                                        Save as draft
                                                    </button>
                                                    <button className="btn btn-primary mb-3 mx-0 ml-2 mr-2" onClick={this.submit}>
                                                        Submit
                                                    </button>
                                                </div>

                                            </div>
                                        ) : (
                                            data && data.surveyQuestionAnswer && data.surveyQuestionAnswer.length === 0 ? (
                                                <div className="row m-t-30">
                                                    <div className='col dnf-wrapper'>
                                                        <div className='dnf-content dnf-exclam'>
                                                            <p className='dnf-exclam-mark'>!</p>
                                                        </div>
                                                        <div className='dnf-content dnf-text'>
                                                            <p className='dnf'>Data Not Found</p>
                                                            <p>Maybe go back and try a different keyword?</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div></div>
                                            )
                                        )}


                                    </>
                                        :
                                        <>

                                            <div className="starting-time">
                                                <div className="col-8 mt-5">
                                                    <h2 className='survey-start-text'>Survey Starts In</h2>
                                                    <CountdownTimer targetDate={targetStartDate} />
                                                </div>
                                                <div className='d-flex mb-3'>
                                                    <div className='survey-part-info'>
                                                        <h4>Start Date:</h4>
                                                        <p>{participantAttendees.startTime} <br />
                                                            {participantAttendees.startDate}  </p>
                                                    </div>
                                                    <div className='survey-part-info'>
                                                        <h4>End Date: </h4>
                                                        <p>{participantAttendees.endTime} <br />
                                                            {participantAttendees.endDate}  </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    }
                                    {/* </fieldset> */}


                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                }
                {this.state.loading && !surveyExpired &&
                <SurveyExpired></SurveyExpired>}
                <ToastContainer />
            </div>
        );
    }
}